<script setup>
import useForm from '~/composables/useForm'
import {feedback} from '~/api/feedback'

const props = defineProps({
  modalData: Object,
})

const emit = defineEmits(['success', 'failure'])
const success = ref(false)
const close = () => props.modalData.isShow = false




const titleModal = ref('')
const textModalSuccess = ref('')

if (props.modalData.type === 'project') {
  titleModal.value = 'Оставить отклик'
  textModalSuccess.value = 'Отклик'
} else if (props.modalData.type === 'business') {
  titleModal.value = 'Заказать обратный звонок'
  textModalSuccess.value = 'Запрос на звонок'
} else {
  titleModal.value = 'Запросить контакты'
  textModalSuccess.value = 'Запрос'
}


const {
  form,
  errors,
  submit,
  loading
} = useForm(feedback, async data => {
  form.value.name = '';
  form.value.phone = '';
  success.value = true

  setTimeout(() => {
    success.value = false
  }, 5000)
  emit('success', data, form.value)
}, async data => {
  emit('failure', data, form.value)
})
form.value.type = props.modalData.type

if (props.modalData.type === 'project' ) {
  form.value.vacancy_id = props.modalData.vacancy_id
} else if (props.modalData.type === 'specialist') {
  form.value.specialist_id = props.modalData.specialist_id
}
</script>

<template>
  <div class="business-modal-wrapper" v-if="props.modalData.isShow === true">
    <div class="business-modal">
      <img class="icon-close" src="" alt="" @click="close()">
      <h3>{{ titleModal }}</h3>
      <p class="business-form-right-success-message" v-if="success">{{ textModalSuccess }} успешно отправлен. Мы скоро свяжемся с
        вами!</p>
      <form @submit.prevent="submit()">
        <AtomInput
          name="Имя"
          v-model="form.name"
          :error="errors.name"
        />
        <AtomInput
          name="Номер телефона"
          v-model="form.phone"
          :error="errors.phone"
        />
        <div class="form-note"> Нажимая на кнопку вы принимаете условия
          <a href="/offer" rel="noopener noreferrer" target="_blank">Оферты</a>
          и <a href="/privacy" rel="noopener noreferrer" target="_blank">Политики Конфиденциальности</a>
        </div>

        <div class="business-form-right-social-button">
          <button type="button" class="base-button outlined" @click="close()">
            <span>закрыть</span>
            <span class="bg"></span>
            <span class="slice slice-1"></span>
            <span class="slice slice-2"></span>
          </button>
          <button type="submit" class="base-button primary">
            <span>отправить</span>
            <span class="bg"></span>
            <span class="slice slice-1"></span>
            <span class="slice slice-2"></span>
          </button>
        </div>
      </form>
    </div>


  </div>
</template>

<style scoped lang="scss">
.business {
  img {
    object-fit: contain;
  }

  section {
    margin-bottom: 130px;
  }

  &-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 20px;
    border-radius: 30px;
    background-color: var(--color-bg-secondary);
    margin-bottom: 25px;

    &-title {
      margin-bottom: 24px;

      p {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 32px;
        font-size: 54px;
        font-weight: 900;
        line-height: 70px;
        letter-spacing: 1.25px;
        text-align: center;
        text-transform: uppercase
      }

      img {
        display: inline-block;
      }
    }

    &-subtitle {
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 50px;
      text-align: center;
    }
  }

  &-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .2);
    z-index: 9999999999;
  }

  &-modal {
    margin: 40px auto;
    background-color: var(--color-bg);
    padding: 32px;
    text-align: center;
    max-width: 450px;
    border-radius: 16px;

    .icon-close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 24px;
      color: var(--color-elements-tertiary);
      cursor: pointer;
    }

    h3 {
      font-size: 28px;
      font-weight: 900;
      line-height: 38px;
      margin-bottom: 32px;
    }

    .input-container {
      margin-bottom: 16px;
    }

    .input-container:last-child {
      margin-bottom: 32px;
    }

    .form-note {
      text-align: center;
      margin-bottom: 32px;
    }

    button {
      width: 100%;
    }
  }
}

.business-form-right-social-button {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.business-form-right-success-message {
  color: var(--color-green);
  margin-bottom: 20px;
}
@media screen and (max-width: 810px) {
  .business-form-right-social-button {
    display: flex;
    flex-direction: column-reverse;
    gap: 15px;
  }
}
</style>
